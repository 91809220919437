/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import ActivityList from "./ActivityList";
import { useSearchParams } from "react-router-dom";
import { benches } from "../data/benches";
import MenuPeriod from "./MenuPeriod";

export default function BenchActivity() {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const id = params.get("_id");
  const bench = benches[Number(id) - 1];
  return (
    <div tw="mt-10">
      <div tw="flex flex-row justify-between">
        <h2 tw="px-4 mb-5 text-xl font-semibold leading-7 text-gray-900 sm:px-6 lg:px-8">
          {t("activity_title")} {bench.name}
        </h2>
        <MenuPeriod />
      </div>

      <ActivityList />
    </div>
  );
}
