/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { medias } from "../data/medias";
import { benches } from "../data/benches";
import UpdateButton from "./UpdateButton";

export default function AddGroup() {
  const { t } = useTranslation();

  return (
    <div tw="space-y-12">
      <div tw="border-b border-gray-900/10 pb-12">
        <div tw="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div tw="col-span-full">
            <label
              htmlFor="name"
              tw="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("name")}
            </label>
            <div tw="mt-2">
              <div tw="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  type="text"
                  tw="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div tw="border-b border-gray-900/10 pb-12">
        <h2 tw="text-base font-semibold leading-7 text-gray-900">
          {t("medias")}
        </h2>
        <div tw=" space-y-10">
          <fieldset tw="mt-6 space-y-6 text-sm leading-6">
            <div tw=" w-1/2 overflow-hidden grid grid-cols-2 gap-px sm:divide-y-0">
              {medias.map((media) => (
                <div key={media.id} tw="relative flex items-start p-4">
                  <div tw="flex h-6 items-center">
                    <input
                      id="comments"
                      aria-describedby="comments-description"
                      name="comments"
                      type="checkbox"
                      tw="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div tw="ml-3 text-sm leading-6">
                    <label htmlFor="comments" tw="font-medium text-gray-900">
                      {t(media.name)}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
      </div>

      <div>
        <h2 tw="text-base font-semibold leading-7 text-gray-900">
          {t("available_audio")}
        </h2>

        <ul role="list" tw="mt-6 divide-y divide-gray-100 text-sm leading-6">
          {benches.map((bench) => (
            <li key={bench.id} tw="flex gap-x-6 py-6">
              <div tw="flex h-6 items-center">
                <input
                  type="checkbox"
                  tw="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div tw="flex flex-row items-center gap-x-5">
                <div tw="font-medium text-gray-900">{bench.name}</div>
              </div>
            </li>
          ))}
          <div tw="flex border-t border-gray-100 pt-6">
            <button
              type="button"
              tw="flex flex-row text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              <span aria-hidden="true">+</span> <UpdateButton>add_bench</UpdateButton>
            </button>
          </div>
        </ul>
      </div>
    </div>
  );
}
