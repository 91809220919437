/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { useState } from "react";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

const languages = [
  {
    id: "fr",
    name: "Français",
  },
  {
    id: "gb",
    name: "English",
  },
];

export default function LanguageMenu() {
  const [selected, setSelected] = useState(languages[0]);
  const { i18n } = useTranslation();

  const onChange = (id) => {
    i18n.changeLanguage(id);
  };

  return (
    <Listbox value={selected} onChange={setSelected}>
      <>
        <div tw="relative">
          <Listbox.Button tw="relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
            <span tw="flex items-center">
              <ReactCountryFlag
                countryCode={selected.id}
                svg
                style={{
                  width: "1rem",
                  height: "1rem",
                }}
              />
              <span tw="ml-3 block truncate">{selected.name}</span>
            </span>
            <span tw="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <ChevronUpDownIcon
                tw="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Listbox.Options tw="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {languages.map((language) => (
              <Listbox.Option
                key={language.id}
                tw="relative cursor-default select-none py-2 pl-3 pr-9"
                value={language}
                onClick={() => {
                  onChange(language.id);
                }}
              >
                {({ selected, active }) => (
                  <>
                    <div tw="flex items-center">
                      <ReactCountryFlag
                        countryCode={language.id}
                        svg
                        style={{
                          width: "1rem",
                          height: "1rem",
                        }}
                      />
                      <span
                        css={[
                          selected ? tw`font-semibold` : tw`font-normal`,
                          tw`ml-3 block truncate`,
                        ]}
                      >
                        {language.name}
                      </span>
                    </div>

                    {selected ? (
                      <span
                        css={[
                          active ? tw`text-white` : tw`text-indigo-600`,
                          tw`absolute inset-y-0 right-0 flex items-center pr-4`,
                        ]}
                      >
                        <CheckIcon tw="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </>
    </Listbox>
  );
}
