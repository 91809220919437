/** @jsxImportSource @emotion/react */
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import tw from "twin.macro";
import { benches } from "../../shared/data/benches";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const statuses = {
  offline: tw`text-gray-500 bg-gray-100/10`,
  online: tw`text-green-400 bg-green-400/10`,
  completed: tw`text-blue-500 bg-blue-100/10`,
  inuse: tw`text-green-400 bg-green-400/10`,
  error: tw`text-red-400 bg-red-400/10`,
};

export default function BenchList() {
  const { t } = useTranslation();
  const {id} = useParams();
  const group=id;
  const [benchList, setBenchList] = useState([]);

  useEffect(() => {
    if (group !== undefined) {
      const list = benches.filter((bench) => bench.group == parseInt(group));
      setBenchList(list);
    } else {
      setBenchList(benches);
    }
  }, [group]);

  return (
    <ul role="list" tw="divide-y divide-gray-300 overflow-auto">
      {benchList.map((bench) => (
        <li key={bench.id}>
          <Link
            to={bench.href}
            tw="relative flex flex-row p-5 items-center"
          >
            <div tw="min-w-0 flex-auto flex items-center flex-row py-4 space-x-4">
              <div tw="flex items-center gap-x-3">
                <div
                  css={[statuses[bench.status], tw`flex-none rounded-full p-1`]}
                >
                  <div tw="h-2 w-2 rounded-full bg-current" />
                </div>
                <h2 tw="min-w-0 text-sm leading-6">
                  <span tw="truncate">{bench.name}</span>
                </h2>
              </div>
              <div tw=" flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                <p tw="truncate">{t(bench.statusText)}</p>
                <p tw="whitespace-nowrap">{bench.statusTime}</p>
              </div>
            </div>
            <ChevronRightIcon
              tw="h-5 w-5 flex-none text-gray-400"
              aria-hidden="true"
            />
          </Link>
        </li>
      ))}
    </ul>
  );
}
