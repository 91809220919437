import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import gb from "./locales/gb.json";
import fr from "./locales/fr.json";

i18next
  .use(initReactI18next)
  .init({
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false,
    },
    debug: process.env.NODE_ENV === "development",
    resources: {
      fr: {
        translation: fr,
      },
      gb: {
        translation: gb,
      },
    },
  });

export default i18next;