/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { GlobeAmericasIcon, LanguageIcon, BookOpenIcon, CloudIcon, MusicalNoteIcon, MegaphoneIcon } from "@heroicons/react/24/outline";
export const medias = [
    {
      id: 1,
      name: "discovery",
      href: "/media/discovery",
      icon : GlobeAmericasIcon,
      current: false,
      color: tw`bg-[rgba(63, 81, 181, 0.5)]`,
    },
    {
      id: 2,
      name: "cultural",
      href: "/media/cultural",
      icon : LanguageIcon,
      current: false,
      color: tw`bg-[rgba(233, 30, 99, 0.5)]`,
    },
    {
      id: 3,
      name: "music",
      href: "/media/music",
      icon : MusicalNoteIcon,
      current: false,
      color: tw`bg-[rgba(77, 182, 172, 0.5)]`,
    },
    {
      id: 4,
      name: "audioBook",
      href: "/media/audioBook",
      icon : BookOpenIcon,
      current: false,
      color: tw`bg-[rgba(66, 133, 244, 0.5)]`,
    },
    {
      id: 5,
      name: "atmosphere",
      href: "/media/atmosphere",
      icon : CloudIcon,
      current: false,
      color: tw`bg-[rgba(156, 39, 176, 0.5)]`,
    },
    {
      id: 6,
      name: "alerts",
      href: "/media/alerts",
      icon : MegaphoneIcon,
      current: false,
      color: tw`bg-[rgba(66, 133, 244, 0.2)]`,
    },
  ];