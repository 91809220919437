/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MediaList from "../../shared/components/MediaList";
import AddAudio from "../../shared/components/AddAudio";
import AddPannel from "../../shared/components/AddPannel";
import { createPortal } from "react-dom";
import useLockedBody from "../../shared/hooks/useLockBodyScroll";

export default function MediaListPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const name = id;
  const [mediaName, setMediaName] = useState(null);
  const [addAudio, SetAddAudio] = useState(false);
  useLockedBody(addAudio, "root");

  useEffect(() => {
    if (name !== undefined) {
      setMediaName(name);
    } else {
      setMediaName("library");
    }
  }, [name]);

  return (
    <main tw="flex flex-col isolate justify-between h-full w-full">
      <div tw=" px-6 w-full text-gray-700 lg:px-8">
        <div tw="flex flex-row items-center justify-between">
          <h1 tw="mt-4 ml-4 font-bold tracking-tight text-xl">{t(mediaName)}</h1>
        </div>
        <div tw="py-2 w-full">
          <MediaList />
        </div>
      </div>
      <div tw="sm:flex sm:items-center self-end">
        <div tw="mt-6 sm:ml-16 sm:mt-0 sm:flex-none ">
          <button
            onClick={() => {
              SetAddAudio(true);
            }}
            tw="w-64 flex justify-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500"
          >
            + {t("add_audio")}
          </button>
        </div>
      </div>
      {addAudio &&
        createPortal(
          <AddPannel
            onClose={() => {
              SetAddAudio(false);
            }}
            onValidate={() => {
              SetAddAudio(false);
            }}
          >
            <AddAudio />
          </AddPannel>,
          document.body
        )}
    </main>
  );
}
