/** @jsxImportSource @emotion/react */
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./layout/Layout";
import AnalyticsPage from "./pages/analytics/AnalyticsPage";
import BenchDetails from "./pages/benchDetails/BenchDetails";
import ErrorPage from "./pages/error/ErrorPage";
import BenchListPage from "./pages/benchList/BenchListPage";
import MediaListPage from "./pages/mediaList/MediaListPage";

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Suspense fallback={<Layout />}>
          <Routes>
            <Route path="/" element={<AnalyticsPage />} />
            <Route path="/benches" element={<BenchListPage />}/>
            <Route path="/group/:id" >
              <Route index element={<BenchListPage />} />
              <Route path="bench" element={<BenchDetails />} />
            </Route>
            <Route path="/medias" element={<MediaListPage />} />
            <Route path="/media/:id?" element={<MediaListPage />} />
            <Route path="/settings" element={<ErrorPage />} />
            <Route path="/help" element={<ErrorPage />} />
            <Route path="/logout" element={<ErrorPage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </Layout>
    </BrowserRouter>
  );
};
