/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { medias } from "../data/medias";

export default function AddBench() {
  const { t } = useTranslation();

  return (
    <div tw="space-y-12">
      <div tw="border-b border-gray-900/10 pb-12">
        <div tw="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div tw="col-span-full">
            <label
              htmlFor="name"
              tw="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("name")}
            </label>
            <div tw="mt-2">
              <div tw="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  type="text"
                  name="username"
                  id="username"
                  autoComplete="username"
                  tw="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div tw="border-b border-gray-900/10 pb-12">
        <div tw=" mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div tw="sm:col-span-3">
            <label
              htmlFor="first-name"
              tw="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("qrdelay")}
            </label>
            <div tw="mt-2">
              <input
                type="number"
                tw="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div tw="sm:col-span-3">
            <label
              htmlFor="privacy"
              tw="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("privacy")}
            </label>
            <div tw="mt-2">
              <select tw="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                <option>{t("public")}</option>
                <option>{t("private")}</option>
              </select>
            </div>
          </div>

          <div tw="col-span-full">
            <label
              htmlFor="street-address"
              tw="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("address")}
            </label>
            <div tw="mt-2">
              <input
                type="text"
                name="street-address"
                id="street-address"
                autoComplete="street-address"
                tw="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div tw="sm:col-span-2 sm:col-start-1">
            <label
              htmlFor="city"
              tw="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("city")}
            </label>
            <div tw="mt-2">
              <input
                type="text"
                name="city"
                id="city"
                autoComplete="address-level2"
                tw="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div tw="sm:col-span-2">
            <label
              htmlFor="region"
              tw="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("state_province")}
            </label>
            <div tw="mt-2">
              <input
                type="text"
                name="region"
                id="region"
                autoComplete="address-level1"
                tw="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div tw="sm:col-span-2">
            <label
              htmlFor="postal-code"
              tw="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("zip_postalcode")}
            </label>
            <div tw="mt-2">
              <input
                type="text"
                name="postal-code"
                id="postal-code"
                autoComplete="postal-code"
                tw="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
      </div>

      <div tw="border-b border-gray-900/10 pb-12">
        <h2 tw="text-base font-semibold leading-7 text-gray-900">
          {t("medias")}
        </h2>
        <div tw=" space-y-10">
          <fieldset tw="mt-6 space-y-6 text-sm leading-6">
            <div tw=" w-1/2 overflow-hidden grid grid-cols-2 gap-px sm:divide-y-0">
              {medias.map((media) => (
                <div key={media.id} tw="relative flex items-start p-4">
                  <div tw="flex h-6 items-center">
                    <input
                      id="comments"
                      aria-describedby="comments-description"
                      name="comments"
                      type="checkbox"
                      tw="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div tw="ml-3 text-sm leading-6">
                    <label htmlFor="comments" tw="font-medium text-gray-900">
                      {t(media.name)}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
}
