/** @jsxImportSource @emotion/react */
//import { ChevronRightIcon } from "@heroicons/react/20/solid";
import tw from "twin.macro";
import { useTranslation } from "react-i18next";

import { useState } from "react";
import BenchOverview from "../../shared/components/BenchOverview";
import BenchActivity from "../../shared/components/BenchActivity";
import BenchSettings from "../../shared/components/BenchSettings";

const secondaryNavigation = [
  { name: "overview" },
  { name: "activity"},
  { name: "settings" },
];

export default function BenchDetails() {
  const { t } = useTranslation();
  const [nav, SetNav] = useState("overview");
  return (
    <main>
        {/* Secondary navigation */}
        <nav tw="flex overflow-x-auto bg-white py-4 -m-5">
          <ul
            role="list"
            tw="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
          >
            {secondaryNavigation.map((item) => (
              <li key={item.name}>
                <button
                  onClick={()=>{ SetNav(item.name);}}
                  css={nav===item.name ? tw`text-indigo-400` : tw``}
                >
                  {t(item.name)}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <div tw="mt-10">
        {nav === "overview" ? (
          <BenchOverview/>
        ) : 
          nav === "activity" ? (
            <BenchActivity/>
          ) : (
            <BenchSettings/>
          )
        }
        </div>
    </main>
  );
}
