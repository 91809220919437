/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { activities } from "../../shared/data/activities";

const statuses = {
    offline: tw`text-gray-500 bg-gray-100/10`,
    online: tw`text-green-400 bg-green-400/10`,
    completed: tw`text-blue-500 bg-blue-100/10`,
    inuse: tw`text-green-400 bg-green-400/10`,
    error: tw`text-red-400 bg-red-400/10`,
  };

export default function ActivityList() {
  const { t } = useTranslation();
  return (
    <div tw=" shadow rounded-xl bg-white p-5">
          <table tw=" w-full whitespace-nowrap text-left">
            <colgroup>
              <col tw="w-full sm:w-4/12" />
              <col tw="lg:w-4/12" />
              <col tw="lg:w-2/12" />
              <col tw="lg:w-1/12" />
              <col tw="lg:w-1/12" />
            </colgroup>
            <thead tw="border-b border-gray-50 text-sm leading-6 text-gray-900">
              <tr>
                <th
                  scope="col"
                  tw="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8"
                >
                  {t("medias")}
                </th>
                <th
                  scope="col"
                  tw="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                >
                  Status
                </th>
                <th
                  scope="col"
                  tw="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20"
                >
                  {t("duration")}
                </th>
                <th
                  scope="col"
                  tw="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                >
                  {t("activity_time")}
                </th>
              </tr>
            </thead>
            <tbody tw="divide-y divide-white/5">
              {activities.map((activity) => (
                <tr key={activity.id}>
                  <td tw="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                    <div tw="flex gap-x-3">
                      <div tw=" text-sm leading-6 text-gray-400">
                        {t(activity.media)}
                      </div>
                    </div>
                  </td>
                  <td tw="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                    <div tw="flex items-center justify-end gap-x-2 sm:justify-start">
                      <div
                        css={[
                          statuses[activity.status],
                          "flex-none rounded-full p-1",
                        ]}
                      >
                        <div tw="h-1.5 w-1.5 rounded-full bg-current" />
                      </div>
                      <div tw="text-gray-400">{t(activity.statusText)}</div>
                    </div>
                  </td>
                  <td tw="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20">
                    {activity.duration}
                  </td>
                  <td tw="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                    {activity.time}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  );
}