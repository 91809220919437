export const benches = [
    {
      id: 1,
      name: "Centre Jaude",
      href: "/group/1/bench?_id=1",
      initial: "S",
      current: false,
      status: "offline",
      statusText : "neutral",
      statusTime : "3h 16min",
      privacy : "public",
      group : 1,
      lat : 45.774771,
      lng : 3.082285,
    },
    {
      id: 2,
      name: "Maison de la culture",
      href: "/group/1/bench?_id=2",
      initial: "A",
      current: false,
      status: "online",
      statusText : "in_use",
      statusTime : "25min",
      privacy : "private",
      group : 1,
      lat : 45.770785,
      lng : 3.085149,
    },
    {
      id: 3,
      name: "La Mémoire des Crapauds de Mark Brusse",
      href: "/group/2/bench?_id=3",
      initial: "B",
      current: false,
      status: "error",
      statusText : "error",
      statusTime : "16h 6min",
      privacy : "public",
      group : 2,
      lat : 45.772718,
      lng : 3.088794,
    },
    {
      id: 4,
      name: "Bassin du Triton",
      href: "/group/2/bench?_id=4",
      initial: "B",
      current: false,
      status: "online",
      statusText : "in_use",
      statusTime : "6min",
      privacy : "public",
      group : 2,
      lat : 45.772154,
      lng: 3.089093,
    },
    {
      id: 5,
      name: "Secoya",
      href: "/group/2/bench?_id=5",
      initial: "B",
      current: false,
      status: "online",
      statusText : "in_use",
      statusTime : "14min",
      privacy : "public",
      group : 2,
      lat : 45.773164,
      lng: 3.088238,
    },
  ];