/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import {
  BellIcon,
  Cog6ToothIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
  ExclamationCircleIcon,
  ArchiveBoxIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import logo from "../assets/logo-black-text.svg";
import LanguageMenu from "../shared/components/LanguageMenu";
import logoPresta from "../assets/logopresta.png";
import { groups } from "../shared/data/groups";
import { medias } from "../shared/data/medias";
import { NavLink, NavLinkProps } from "react-router-dom";

const CustomNavLink = ({ children, ...props }: NavLinkProps) => {
  return (
    <NavLink {...props}>
      {({ isActive }) => (
        <div
          css={[
            tw`flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-100 hover:text-indigo-600`,
            isActive ? tw`bg-gray-50 text-indigo-600` : "",
          ]}
        >
          {/* @ts-ignore */}
          {children}
        </div>
      )}
    </NavLink>
  );
};

export const Layout = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <div tw="h-full w-full relative z-0">
      {/* Static sidebar for desktop */}
      <div tw="hidden lg:fixed lg:flex lg:w-[15%] h-full lg:flex-col bg-white border-r border-gray-200 items-center gap-y-3">
        <img tw="mt-2 w-[75%]" src={logo} alt="logo" />
        <div tw="flex flex-col w-full px-6 h-full overflow-y-auto">
          <nav tw="flex flex-1 w-full my-5 h-full ">
            <ul role="list" tw="flex flex-col w-full gap-y-7">
              <li>
                <CustomNavLink to="/">
                  <HomeIcon tw="h-6 w-6 text-gray-400 " aria-hidden="true" />
                  {t("dashboard")}
                </CustomNavLink>
                <CustomNavLink to="/benches">
                  <ListBulletIcon
                    tw="h-6 w-6  text-gray-400 group-hover:text-indigo-600"
                    aria-hidden="true"
                  />
                  {t("benches")}
                </CustomNavLink>
                <CustomNavLink to="/medias">
                  <ArchiveBoxIcon
                    tw="h-6 w-6  text-gray-400 group-hover:text-indigo-600"
                    aria-hidden="true"
                  />
                  {t("library")}
                </CustomNavLink>
              </li>
              <li>
                <div tw="text-xs font-semibold leading-6 text-gray-400">
                  {t("groups")}
                </div>
                {groups.map((group) => (
                  <CustomNavLink to={group.href} key={group.id}>
                    <span tw="flex h-6 w-6 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                      {group.initial}
                    </span>
                    {group.name}
                  </CustomNavLink>
                ))}
              </li>
              <li>
                <div tw="text-xs font-semibold leading-6 text-gray-400">
                  {t("medias")}
                </div>
                {medias.map((media) => (
                  <CustomNavLink to={media.href} key={media.id}>
                    <media.icon
                      tw="h-6 w-6 text-gray-400 group-hover:text-indigo-600"
                      aria-hidden="true"
                    />
                    <span tw="truncate">{t(media.name)}</span>
                  </CustomNavLink>
                ))}
              </li>
              <li>
                <CustomNavLink to="/settings">
                  <Cog6ToothIcon
                    tw="h-6 w-6  text-gray-400 group-hover:text-indigo-600"
                    aria-hidden="true"
                  />
                  {t("settings")}
                </CustomNavLink>
                <CustomNavLink to="/help">
                  <ExclamationCircleIcon
                    tw="h-6 w-6  text-gray-400 group-hover:text-indigo-600"
                    aria-hidden="true"
                  />
                  {t("getHelp")}
                </CustomNavLink>
                <CustomNavLink to="logout">
                  <ArrowRightOnRectangleIcon
                    tw="h-6 w-6  text-gray-400 group-hover:text-indigo-600"
                    aria-hidden="true"
                  />
                  {t("logOut")}
                </CustomNavLink>
              </li>
            </ul>
          </nav>
        </div>
        <img tw="w-1/3 self-start ml-6 mb-2" src={logoPresta} />
      </div>
      <div tw="sticky top-0 border-b border-gray-200 shadow-sm left-[15%] w-[85%] z-40 flex h-[8%] items-center gap-x-4  bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8 ">
        {/* Separator */}
        <div tw="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

        <div tw="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <form tw="relative flex flex-1" action="#" method="GET">
            <label htmlFor="search-field" tw="sr-only">
              Search
            </label>
            <MagnifyingGlassIcon
              tw="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
              aria-hidden="true"
            />
            <input
              id="search-field"
              tw="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm "
              placeholder={t("research_placeholder")}
              type="search"
              name="search"
            />
          </form>
          <div tw="flex items-center gap-x-4 lg:gap-x-6">
            <button
              type="button"
              tw="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
            >
              <span tw="sr-only">View notifications</span>
              <BellIcon tw="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              tw="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
              aria-hidden="true"
            />

            {/* Language Selector */}
            <LanguageMenu />
          </div>
        </div>
      </div>
      <div tw="ml-[15%] h-[92%]  bg-gray-100">
        <main tw="flex items-stretch justify-center w-full p-5 bg-gray-100">
          <div tw="flex-1 w-[85%] h-full">{children}</div>
        </main>
      </div>
    </div>
  );
};
