/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import img from "../../assets/programming.svg";
import tw from "twin.macro";

export default function ErrorPage() {
  const { t } = useTranslation();
  return (
    <main tw="flex h-full w-full items-center justify-center">
      <div tw="flex items-center justify-center flex-col mx-auto max-w-7xl px-6 py-24 text-center text-gray-700 lg:px-8">
        <h1 tw="mt-4 text-3xl font-bold tracking-tight  sm:text-5xl">
          {t("indev_title")}
        </h1>
        <img src={img}  tw="w-1/2 my-16"/>
        <p tw="mt-4 text-base sm:mt-6">{t("indev_message")}</p>
        <div tw="mt-10 flex justify-center">
          <Link to="/" tw="text-sm font-semibold leading-7 ">
            <span aria-hidden="true">{t("link")}</span>
          </Link>
        </div>
      </div>
    </main>
  );
}
