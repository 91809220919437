import tw, { GlobalStyles } from "twin.macro";
import { Global } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppRoutes } from "./Routes";

function App() {
 const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  });

  return (
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <GlobalStyles />
          <Global styles={{ body: tw`antialiased font-sans` }} />
          <AppRoutes />
        </QueryClientProvider>
      </div>
  );
}

export default App;
