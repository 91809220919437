/** @jsxImportSource @emotion/react */
import { Switch } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import tw from "twin.macro";
import { benches } from "../data/benches";
import UpdateButton from "./UpdateButton";
import { useState } from "react";
import { medias } from "../data/medias";
import { audios } from "../data/audios";

export default function BenchSettings() {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const id = params.get("_id");
  const bench = benches[Number(id) - 1];
  const [toggle, setToggle] = useState(true);

  return (
    <div>
      <div tw="ml-5 mb-5">
        <h2 tw="text-lg font-semibold leading-7 text-gray-900">
          {t("settings")}
        </h2>
        <p tw="mt-1 text-base leading-6 text-gray-500">
          {t("settings_header")}
        </p>
      </div>

      <main tw="lg:flex-auto shadow rounded-xl bg-white py-5 px-8">
        <div tw="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
          <div>
            <h2 tw=" text-lg font-semibold leading-7 text-indigo-600">
              {t("general_settings")}
            </h2>
            <dl tw="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              <div tw="pt-6 sm:flex">
                <dt tw="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  {t("name")}
                </dt>
                <dd tw="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div tw="text-gray-400">{bench.name}</div>
                  <UpdateButton>update_button</UpdateButton>
                </dd>
              </div>
              <div tw="pt-6 sm:flex">
                <dt tw="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  {t("qrdelay")}{" "}
                </dt>
                <dd tw="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div tw="text-gray-400">20 mins</div>
                  <UpdateButton>update_button</UpdateButton>
                </dd>
              </div>
              <Switch.Group as="div" tw="flex pt-6">
                <Switch.Label
                  as="dt"
                  tw="w-64 flex-none pr-6 font-medium text-gray-900"
                  passive
                >
                  {t("switch")}
                </Switch.Label>
                <dd tw="flex flex-auto items-center justify-end">
                  <Switch
                    checked={toggle}
                    onChange={setToggle}
                    css={[
                      toggle ? tw`bg-indigo-600` : tw`bg-gray-200`,
                      tw`flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline-none focus-visible:outline-black`,
                    ]}
                  >
                    <span
                      aria-hidden="true"
                      css={[
                        toggle ? tw`translate-x-3.5` : tw`translate-x-0`,
                        tw`h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out`,
                      ]}
                    />
                  </Switch>
                </dd>
              </Switch.Group>

              <div tw="pt-6 sm:flex">
                <dt tw="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  {t("privacy")}
                </dt>
                <dd tw="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div tw="text-gray-400">{t(bench.privacy)}</div>
                  <UpdateButton>update_button</UpdateButton>
                </dd>
              </div>
            </dl>
          </div>

          <div>
            <h2 tw=" text-lg font-semibold leading-7 text-indigo-600">
              {t("medias")}
            </h2>
            <p tw="mt-1 text-sm leading-6 text-gray-500">
              {t("medias_settings")}
            </p>

            <fieldset tw="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              <div tw=" w-1/2  divide-y divide-gray-200 overflow-hidden grid grid-cols-2 gap-px sm:divide-y-0">
                {medias.map((media) => (
                  <div key={media.id} tw="relative flex items-start p-4">
                    <div tw="flex h-6 items-center">
                      <input
                        id="comments"
                        aria-describedby="comments-description"
                        name="comments"
                        type="checkbox"
                        tw="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div tw="ml-3 text-sm leading-6">
                      <label htmlFor="comments" tw="font-medium text-gray-900">
                        {t(media.name)}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>

          <div>
            <h2 tw=" text-lg font-semibold leading-7 text-indigo-600">
              {t("available_audio")}
            </h2>

            <ul
              role="list"
              tw="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"
            >
              {audios.map((audio) => (
                <li key={audio.id} tw="flex justify-between gap-x-6 py-6">
                  <div tw="flex flex-row items-center gap-x-5">
                    <div tw="font-medium text-gray-900">{audio.name}</div>
                    <div tw="text-sm text-gray-400">
                      {audio.duration} - {t(audio.media)}
                    </div>
                  </div>
                  <UpdateButton>update_button</UpdateButton>
                </li>
              ))}
              <div tw="flex border-t border-gray-100 pt-6">
                <button
                  type="button"
                  tw=" flex flex-row text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                >
                  <span aria-hidden="true">+</span><UpdateButton>add_audio</UpdateButton>
                </button>
              </div>
            </ul>
          </div>

          <div>
            <h2 tw=" text-lg font-semibold leading-7 text-indigo-600">
              {t("schedule")}
            </h2>
            <p tw="mt-1 text-sm leading-6 text-gray-500">
              {t("schedule_description")}
              <dl tw="mt-6 py-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div tw="sm:flex">
                  <dd tw="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <dt tw="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                      Volume
                    </dt>
                    <UpdateButton>update_button</UpdateButton>
                  </dd>
                </div>
                <div tw="pt-6 sm:flex">
                  <dd tw="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <dt tw="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                      {t("switch")}
                    </dt>
                    <UpdateButton>update_button</UpdateButton>
                  </dd>
                </div>
                <div tw="pt-6 sm:flex">
                  <dd tw="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <dt tw="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                      {t("medias")}
                    </dt>
                    <UpdateButton>update_button</UpdateButton>
                  </dd>
                </div>
                <div tw="pt-6 sm:flex">
                  <dd tw="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <dt tw="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                      {t("alerts")}
                    </dt>
                    <UpdateButton>update_button</UpdateButton>
                  </dd>
                </div>
              </dl>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}
