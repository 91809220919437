/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { useParams } from "react-router-dom";
import { SpeakerWaveIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { audios } from "../data/audios";
import UpdateButton from "./UpdateButton";

export default function MediaList() {
  const { t } = useTranslation();
  const { id } = useParams();
  const name = id;
  const [audioList, setAudioList] = useState([]);

  useEffect(() => {
    if (name !== undefined) {
      const list = audios.filter((audio) => audio.media === name);
      setAudioList(list);
    } else {
      setAudioList(audios);
    }
  }, [name]);

  return (
    <div tw="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div tw="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div tw="shadow rounded-xl p-5 bg-white">
          <table tw="min-w-full divide-y divide-gray-300 ">
            <thead>
              <tr>
                <th
                  scope="col"
                  tw="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  {t("name")}
                </th>
                <th
                  scope="col"
                  tw="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {t("artist")}
                </th>
                <th
                  scope="col"
                  tw="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Description
                </th>
                <th
                  scope="col"
                  tw="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {t("duration")}
                </th>
                <th
                  scope="col"
                  tw="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
                <th
                  scope="col"
                  tw="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {t("expiration_date")}
                </th>
                <th
                  scope="col"
                  tw="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {t("nb_listen")}
                </th>
                <th scope="col" tw="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span tw="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody tw="divide-y divide-gray-200 ">
              {audioList.map((audio) => (
                <tr key={audio.id}>
                  <td tw="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                    <div tw="flex items-center">
                      <div tw="h-12 w-12 flex items-center justify-center">
                        {audio.image !== "" ? (
                          <img
                            tw="h-12 w-12 rounded-full"
                            src={audio.image}
                            alt=""
                          />
                        ) : (
                          <SpeakerWaveIcon tw="h-10 text-gray-400" />
                        )}
                      </div>
                      <div tw="ml-4">
                        <div tw="font-medium text-gray-900">{audio.name}</div>
                      </div>
                    </div>
                  </td>
                  <td tw=" px-3 py-5 text-sm text-gray-500">
                    <div tw="text-gray-900">{audio.artist}</div>
                  </td>
                  <td tw=" px-3 py-5 text-sm text-gray-500">
                    <div tw="text-gray-900">{audio.descritpion}</div>
                  </td>
                  <td tw=" px-3 py-5 text-sm text-gray-500">
                    <div tw="text-gray-900">{audio.duration}</div>
                  </td>
                  <td tw=" px-3 py-5 text-sm text-gray-500">
                    {audio.status === "active" ? (
                      <span tw="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        {t("active")}
                      </span>
                    ) : (
                      <span tw="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                        {t("inactive")}
                      </span>
                    )}
                  </td>
                  <td tw=" px-3 py-5 text-sm text-gray-500">{audio.endDate}</td>
                  <td tw=" px-3 py-5 text-sm text-gray-500">
                    {audio.nbListening}
                  </td>
                  <td tw="relative  py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <UpdateButton>update_button</UpdateButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
