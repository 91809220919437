/** @jsxImportSource @emotion/react */
import {
  ArrowDownTrayIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { medias } from "../data/medias";
import { benches } from "../data/benches";

export default function AddAudio() {
  const { t } = useTranslation();

  return (
    <div tw="border-b border-gray-900/10 pb-12">
      <div tw="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div tw="sm:col-span-3">
          <label
            htmlFor="name"
            tw="block text-sm font-medium leading-6 text-gray-900"
          >
            {t("name")}
          </label>
          <div tw="mt-2">
            <input
              type="text"
              tw="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div tw="sm:col-span-3">
          <label
            htmlFor="artist"
            tw="block text-sm font-medium leading-6 text-gray-900"
          >
            {t("artist")}
          </label>
          <div tw="mt-2">
            <input
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              tw="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div tw="col-span-full ">
          <label
            htmlFor="about"
            tw="block text-sm font-medium leading-6 text-gray-900"
          >
            {t("about")}
          </label>
          <div tw="mt-2">
            <textarea
              rows={3}
              tw="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={""}
            />
          </div>
        </div>

        <div tw="sm:col-span-3">
          <label
            htmlFor="media"
            tw="block text-sm font-medium leading-6 text-gray-900"
          >
            {t("media")}
          </label>
          <div tw="mt-2">
            <select tw="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
              {medias.map((media) => (
                <option key={media.id}>{t(media.name)}</option>
              ))}
            </select>
          </div>
        </div>
        <div tw="sm:col-span-3">
          <label
            htmlFor="expDate"
            tw="block text-sm font-medium leading-6 text-gray-900"
          >
            {t("expiration_date")}
          </label>
          <div tw="mt-2">
            <input
              type="date"
              tw="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>

      <div tw="col-span-full mt-12 ">
        <label
          htmlFor="photo"
          tw="block text-sm font-medium leading-6 text-gray-900"
        >
          Photo
        </label>
        <div tw="col-span-full">
          <div tw="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
            <div tw="text-center">
              <PhotoIcon
                tw="mx-auto h-12 w-12 text-gray-300"
                aria-hidden="true"
              />
              <div tw="mt-4 flex text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  tw="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>{t("upload_file")}</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    tw="sr-only"
                  />
                </label>
                <p tw="pl-1">{t("or_drag_drop")}</p>
              </div>
              <p tw="text-xs leading-5 text-gray-600">PNG, JPG</p>
            </div>
          </div>
        </div>
      </div>

      <div tw="col-span-full mt-12 ">
        <label
          htmlFor="photo"
          tw="block text-sm font-medium leading-6 text-gray-900"
        >
          Audio
        </label>
        <div tw="col-span-full">
          <div tw="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
            <div tw="text-center">
              <ArrowDownTrayIcon
                tw="mx-auto h-12 w-12 text-gray-300"
                aria-hidden="true"
              />
              <div tw="mt-4 flex text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  tw="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>{t("upload_file")}</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    tw="sr-only"
                  />
                </label>
                <p tw="pl-1">{t("or_drag_drop")}</p>
              </div>
              <p tw="text-xs leading-5 text-gray-600">AUDIO</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2 tw="text-base font-semibold leading-7 mt-12 text-gray-900">
          {t("available_on")}
        </h2>

        <ul role="list" tw="mt-6 divide-y divide-gray-100 text-sm leading-6">
          {benches.map((bench) => (
            <li key={bench.id} tw="flex gap-x-6 py-6">
              <div tw="flex h-6 items-center">
                <input
                  type="checkbox"
                  tw="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div tw="flex flex-row items-center gap-x-5">
                <div tw="font-medium text-gray-900">{bench.name}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
