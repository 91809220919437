/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import BenchList from "../../shared/components/BenchList";
import AddBench from "../../shared/components/AddBench";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { groups } from "../../shared/data/groups";
import AddGroup from "../../shared/components/AddGroup";
import AddPannel from "../../shared/components/AddPannel";
import { createPortal } from "react-dom";
import useLockedBody from "../../shared/hooks/useLockBodyScroll";

export default function BenchListPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const group = id;
  const [groupName, setGroupName] = useState("benches");
  const [addBench, SetAddBench] = useState(false);
  const [addGroup, SetAddGroup] = useState(false);
  const navigation = useNavigate();
  useLockedBody(addBench, "root");
  useLockedBody(addGroup, "root");

  useEffect(() => {
    if (group !== undefined) {
      setGroupName(groups[parseInt(group) - 1].name);
    } else {
      setGroupName("benches");
    }
  }, [group]);

  return (
    <main tw="flex flex-col isolate justify-between min-h-full gap-y-8">
      <div tw="mx-auto px-6 w-full text-gray-700 lg:px-8">
        <div tw="flex flex-row items-center justify-between">
          <h1 tw="m-4 font-bold tracking-tight text-xl">{t(groupName)}</h1>
          
        </div>
        <div tw="shadow bg-white rounded-xl p-2">
          <BenchList />
        </div>
      </div>
      <div tw="flex flex-row self-end gap-x-5">
        <button
          onClick={() => {
            SetAddGroup(true);
          }}
          tw="w-52 flex justify-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500"
        >
          + {t("add_group")}
        </button>
        <button
          onClick={() => {
            SetAddBench(true);
          }}
          tw="w-48 flex justify-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500"
        >
          + {t("add_bench")}
        </button>
      </div>

      {addBench
        ? createPortal(
            <AddPannel
              onClose={() => {
                SetAddBench(false);
              }}
              onValidate={() => {
                SetAddBench(false);
                navigation("/group/1/bench?_id=1");
              }}
            >
              <AddBench />
            </AddPannel>,
            document.body
          )
        : addGroup &&
          createPortal(
            <AddPannel
              onClose={() => {
                SetAddGroup(false);
              }}
              onValidate={() => {
                SetAddGroup(false);
                navigation("/group/2");
              }}
            >
              <AddGroup />
            </AddPannel>,
            document.body
          )}
    </main>
  );
}
