export const groups = [
    {
      id: 1,
      name: "Jaude",
      href: "/group/1",
      initial: "M",
    },
    {
      id: 2,
      name: "Jardin Lecoq",
      href: "/group/2",
      initial: "J",
    },
  ];