/** @jsxImportSource @emotion/react */
import "twin.macro";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import tw from "twin.macro";
import { useTranslation } from "react-i18next";
import { Map } from "./Map";
import BenchList from "../../shared/components/BenchList";
import "chart.js/auto";
import { medias } from "../../shared/data/medias";
import MenuPeriod from "../../shared/components/MenuPeriod";
import { Doughnut } from "react-chartjs-2";

const chartData = {
  datasets: [
    {
      data: [30, 55, 110, 10, 3, 20],
      backgroundColor: [
        "rgba(63, 81, 181, 0.5)",
        "rgba(233, 30, 99, 0.5)",
        "rgba(77, 182, 172, 0.5)",
        "rgba(66, 133, 244, 0.5)",
        "rgba(156, 39, 176, 0.5)",
        
        "rgba(66, 133, 244, 0.2)",
      ],
    },
  ],
};

const stats = [
  {
    name: "stat1_title",
    stat: "71,897",
    previousStat: "70,946",
    change: "12%",
    changeType: "increase",
  },
  {
    name: "stat2_title",
    stat: "58.16min",
    previousStat: "56.14min",
    change: "2.02%",
    changeType: "increase",
  },
  {
    name: "stat3_title",
    stat: "24",
    previousStat: "28",
    change: "4.05%",
    changeType: "decrease",
  },
];

const languageStats = [
  {
    id: 1,
    name: "language_fr",
    color: tw`bg-[rgba(77, 182, 172, 0.5)]`,
    val: tw`w-[75%]`,
  },
  {
    id: 2,
    name: "language_en",
    color: tw`bg-[rgba(156, 39, 176, 0.5)]`,
    val: tw`w-[20%]`,
  },
  {
    id: 3,
    name: "language_es",
    color: tw`bg-[rgba(63, 81, 181, 0.5)]`,
    val: tw`w-[5%]`,
  },
];

export default function AnalyticsPage() {
  const { t } = useTranslation();

  return (
    <div tw="w-full">
      <div tw="flex flex-row items-center justify-between">
        <h1 tw="m-4 font-bold tracking-tight text-xl">{t("analytics")}</h1>
        <MenuPeriod />
      </div>
      {/* First row */}
      <dl tw="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div key={item.name} tw="px-6 py-4  bg-white shadow rounded-xl">
            <dt tw="text-base font-normal text-gray-900">{t(item.name)}</dt>
            <dd tw="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div tw="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.stat}
                <span tw="ml-2 text-sm font-medium text-gray-500">
                  {t("from")} {item.previousStat}
                </span>
              </div>

              <div
                css={[
                  item.changeType === "increase"
                    ? tw`bg-green-100 text-green-800`
                    : tw`bg-red-100 text-red-800`,
                  tw`inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`,
                ]}
              >
                {item.changeType === "increase" ? (
                  <ArrowUpIcon
                    tw="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowDownIcon
                    tw="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )}

                <span tw="sr-only">
                  {" "}
                  {item.changeType === "increase"
                    ? "Increased"
                    : "Decreased"}{" "}
                  by{" "}
                </span>
                {item.change}
              </div>
            </dd>
          </div>
        ))}
      </dl>
      {/* Second row */}
      <div tw="w-full grid grid-cols-1 gap-5 lg:grid-cols-2 mt-5 ">
        <div tw="flex flex-col justify-around bg-white shadow rounded-xl p-5">
          <p tw="font-semibold text-lg text-gray-800 ml-5">
            {t("language_chart_title")}
          </p>
          <div>
            {languageStats.map((language) => (
              <div
                key={language.id}
                tw="flex items-center justify-between py-2"
              >
                <p tw="ml-5">{t(language.name)}</p>

                <div tw="flex justify-start rounded-xl w-3/4 h-3 border">
                  <div
                    css={[language.color, language.val, tw`rounded-xl h-3 `]}
                  />
                </div>
              </div>
            ))}
            <div tw="flex flex-row justify-between w-3/4 ml-[25%] border-t-2 border-gray-300 text-gray-300">
              <div>0</div>
              <div>25</div>
              <div>50</div>
              <div>75</div>
              <div>100 %</div>
            </div>
          </div>
        </div>
        <div tw="flex flex-col bg-white shadow rounded-xl p-5 w-full">
          <p tw="font-semibold text-lg text-gray-800 ml-5">
            {t("doughnut_title")}
          </p>
          <div tw="flex flex-row items-center justify-around">
            <div tw="w-1/4">
              <Doughnut data={chartData} />
            </div>
            <div tw="w-3/5 lg:w-2/3 justify-center">
              {medias.map((media) => (
                <div key={media.id} tw="flex flex-row items-center p-2">
                  <div
                    css={[
                      media.color,
                      tw` rounded-xl text-transparent w-12 h-3 ml-5 mr-3`,
                    ]}
                  ></div>
                  {t(media.name)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Third row*/}
      <div tw="flex flex-col lg:flex-row gap-5 mt-5 w-full ">
        <div tw="hidden lg:flex flex-col bg-white shadow justify-between w-full lg:w-2/3 p-5 rounded-xl  ">
          <p tw=" font-semibold text-lg text-gray-800 ml-5 mb-5">
            {t("map_title")}
          </p>
          <Map />
        </div>
        <div tw="flex flex-col w-full p-5 bg-white shadow lg:w-1/3 rounded-xl ">
          <p tw="font-semibold text-lg text-gray-800 ml-5">{t("benches")}</p>
          <BenchList tw="" />
        </div>
      </div>
    </div>
  );
}
