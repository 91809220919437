/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { benches } from "../../shared/data/benches";
import ActivityList from "./ActivityList";
import MenuPeriod from "./MenuPeriod";

const statuses = {
  offline: tw`text-gray-500 bg-gray-100/10`,
  online: tw`text-green-400 bg-green-400/10`,
  completed: tw`text-blue-500 bg-blue-100/10`,
  inuse: tw`text-green-400 bg-green-400/10`,
  error: tw`text-red-400 bg-red-400/10`,
};

const stats = [
  {
    name: "stat1_title",
    stat: "897",
    previousStat: "70,946",
    change: "12%",
    changeType: "increase",
  },
  {
    name: "stat2_title",
    stat: "5.68min",
    previousStat: "56.14min",
    change: "2.02%",
    changeType: "increase",
  },
  {
    name: "stat3_title",
    stat: "32",
    previousStat: "28",
    change: "4.05%",
    changeType: "decrease",
  },
];
export default function BenchList() {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const id = params.get("_id");
  const bench = benches[Number(id) - 1];

  return (
    <div>
      <header>
        {/* Heading */}
        <div tw="bg-white shadow divide-y rounded-xl">
          <div tw="flex flex-col items-start justify-center gap-x-8 gap-y-4 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
            <div tw=" min-w-0 flex-auto my-3">
              <div tw="flex items-center gap-x-3">
                <div
                  css={[statuses[bench.status], tw`flex-none rounded-full p-1`]}
                >
                  <div tw="h-3 w-3 rounded-full bg-current" />
                </div>
                <h2 tw="min-w-0 text-xl font-semibold leading-6">
                  <span tw="truncate">{bench.name}</span>
                </h2>
              </div>
              <div tw="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                <p tw="truncate">{t(bench.statusText)}</p>
                <p tw="whitespace-nowrap">{bench.statusTime}</p>
              </div>
            </div>
           <MenuPeriod/>
          </div>

          {/* Stats */}
          <div tw="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((stat, statIdx) => (
              <div
                key={t(stat.name)}
                css={[
                  statIdx % 2 === 1
                    ? tw`sm:border-l`
                    : statIdx === 2
                    ? tw`lg:border-l`
                    : tw``,
                  tw`py-6 px-4 sm:px-6 lg:px-8`,
                ]}
              >
                <p tw="text-sm font-medium leading-6 text-gray-400">
                  {t(stat.name)}
                </p>
                <p tw="mt-2 flex items-baseline gap-x-2">
                  <span tw="text-4xl font-semibold tracking-tight text-gray-800">
                    {stat.stat}
                  </span>
                </p>
              </div>
            ))}
          </div>
        </div>
      </header>

      {/* Activity list */}
      <div tw="mt-10">
        <h2 tw="px-4 mb-5 text-xl font-semibold leading-7 text-gray-900 sm:px-6 lg:px-8">
          {t("latest_activity")}
        </h2>
        <ActivityList />
      </div>
    </div>
  );
}
