/** @jsxImportSource @emotion/react */
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import tw from "twin.macro";
import { useTranslation } from "react-i18next";
import { Menu } from "@headlessui/react";
import { useState } from "react";

export default function BenchList() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("day");

  return (
    <Menu as="div" tw="relative inline-block text-left">
      <div tw="flex flex-row gap-x-5 items-center text-sm font-medium">
        {t("period")}
        <Menu.Button tw="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {t(filter)}
          <ChevronUpDownIcon
            tw="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Menu.Items tw="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 ">
        <Menu.Item>
          <div
            css={[
              filter === "day"
                ? tw`bg-gray-100 text-gray-900`
                : tw`text-gray-700`,
              tw`block px-4 py-2 text-sm`,
            ]}
            onClick={() => setFilter("day")}
          >
            {t("day")}
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            css={[
              filter === "week"
                ? tw`bg-gray-100 text-gray-900`
                : tw`text-gray-700`,
              tw`block px-4 py-2 text-sm`,
            ]}
            onClick={() => setFilter("week")}
          >
            {t("week")}
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            css={[
              filter === "month"
                ? tw`bg-gray-100 text-gray-900`
                : tw`text-gray-700`,
              tw`block px-4 py-2 text-sm`,
            ]}
            onClick={() => setFilter("month")}
          >
            {t("month")}
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            css={[
              filter === "year"
                ? tw`bg-gray-100 text-gray-900`
                : tw`text-gray-700`,
              tw`block px-4 py-2 text-sm`,
            ]}
            onClick={() => setFilter("year")}
          >
            {t("year")}
          </div>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}
