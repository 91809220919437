/** @jsxImportSource @emotion/react */
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

export default function AddPannel({ onClose, onValidate, children }) {
  const { t } = useTranslation();

  return (
    <div tw="fixed inset-0 w-full h-full bg-gray-500 bg-opacity-75">
      <div tw="fixed inset-y-0 top-0 flex h-full w-1/3 right-0">
        <div tw="flex h-full w-full flex-col bg-white py-6 overflow-auto">
          <div tw="relative mt-6 flex-1 px-4 sm:px-6">
            <div tw="px-4 sm:px-6">
              <div tw="flex items-start justify-between">
                <div tw="text-lg font-semibold leading-6 text-gray-900">
                  {t("add_element")}
                </div>
                <div tw="ml-3 flex h-7 items-center">
                  <button
                    onClick={onClose}
                    tw="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span tw="sr-only">Close panel</span>
                    <XMarkIcon tw="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
              {children}
              <div tw="mt-6 flex items-center justify-end gap-x-6">
                <button
                  onClick={onClose}
                  tw="text-sm font-semibold leading-6 text-gray-900"
                >
                  {t("cancel")}
                </button>
                <button
                  onClick={onValidate}
                  tw="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
