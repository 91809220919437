/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { createPortal } from "react-dom";
import AddPannel from "./AddPannel";
import AddAudio from "./AddAudio";
import useLockedBody from "../hooks/useLockBodyScroll";

export default function UpdateButton({ children }) {
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);
  useLockedBody(clicked, "root");

  return (
    <div>
      <button
        onClick={() => {
          setClicked(true);
        }}
        tw="font-semibold text-indigo-600 hover:text-indigo-500"
      >
        {t(children)}
      </button>
      {clicked &&
        createPortal(
          <AddPannel
            onValidate={() => {
              setClicked(false);
            }}
            onClose={() => {
              setClicked(false);
            }}
          >
            <AddAudio />
          </AddPannel>,
          document.body
        )}
    </div>
  );
}
