import { GoogleMap, Marker } from "@react-google-maps/api";
import mapStyles from "../../google-maps/mapStyles";
import styles from "./Map.module.css";
import { benches } from "../../shared/data/benches";
import { useEffect, useState } from "react";
import { useLoadGoogleMapsQuery } from "../../google-maps/Loader";
import { useNavigate} from "react-router";


const MapSuccess = () => {
  const navigation = useNavigate();
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setLocations(benches);
  }, []);
  
  return (
    <div className={styles.Map}>
      <GoogleMap
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        zoom={16}
        center={{lat :45.773449,lng : 3.086847}}
        options={{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          styles: mapStyles,
        }}
      >
        {locations.map((b) => (
          <Marker
            key={b.id}
            position={{lat : b.lat, lng : b.lng}}
            onClick={()=>{ navigation(b.href);} }
          />
        ))}
      </GoogleMap>
    </div>
  );
};

export function Map() {
  const { status } = useLoadGoogleMapsQuery();

  return status === "success" ? <MapSuccess /> : null;
}
