import { Loader } from "@googlemaps/js-api-loader";
import { useQuery } from "react-query";

export const loader = new Loader({
  apiKey: "AIzaSyAFpykwFH9C3DZ163wFronf6iZ7pHduX8w",
  libraries: ["places"],
});

export const useLoadGoogleMapsQuery = () => {
  return useQuery(
    "@googlemaps/js-api-loader",
    async () => {
      return loader.load();
    },
    {
      staleTime: Infinity,
    }
  );
};