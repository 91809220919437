export const activities = [
    {
        id: 1,
        status: "completed",
        media : "discovery",
        statusText : "completed",
        time : "15h36",
        duration :"26min",
    },
    {
        id: 2,
        status: "inuse",
        media : "music",
        statusText : "in_use",
        time : "13h15",
        duration :"5min",
    },
    {
        id: 3,
        status: "error",
        media : "atmosphere",
        statusText : "error",
        time : "10h03",
        duration :"17min",
    },
    {
        id: 4,
        status: "completed",
        media : "discovery",
        statusText : "completed",
        time : "8h48",
        duration :"12min",
    },
    {
        id: 5,
        status: "completed",
        media : "discovery",
        statusText : "completed",
        time : "7h19",
        duration :"9min",
    },
  ];